<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      :variant="`transparent`"
      :opacity="0.5"
      :blur="`10px`"
      rounded="sm"
      spinner-type="grow"
    >
      <section>
        <b-card no-body>
          <b-card-body>
            <b-img
              v-if="details.verified"
              :src="require('@/assets/images/illustration/badge.svg')"
              class="congratulation-medal"
              alt="Medal Pic"
            />
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('common.general.fullname')"
                  label-for="fullname"
                >
                  <b-form-input
                    id="fullname"
                    v-model="details.fullname"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.email')"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="details.email"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.phone_number')"
                  label-for="email"
                >
                  <b-form-input
                    id="address"
                    v-model="details.phone_number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('common.general.number_street')"
                  label-for="number_street"
                >
                  <b-form-input
                    id="number_street"
                    v-model="details.numberStreet"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.additional_address')"
                  label-for="additional_address"
                >
                  <b-form-input
                    id="additional_address"
                    v-model="details.additionalAddress"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.zip_code')"
                  label-for="zip_code"
                >
                  <b-form-input
                    id="zip_code"
                    v-model="details.zipCode"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.city')"
                  label-for="city"
                >
                  <b-form-input
                    id="city"
                    v-model="details.city"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('common.general.status_code')"
                  label-for="status_code"
                >
                  <b-badge variant="light-primary">
                    {{ details.processStatusCode }}
                  </b-badge>
                </b-form-group></b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.company_already_created')"
                  label-for="company_already_created"
                >
                  <b-form-checkbox
                    v-model="details.company_already_created"
                    :checked="details.company_already_created"
                    switch
                    inline
                  /></b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.creation_of_current_business')"
                  label-for="creation_of_current_business"
                >
                  <b-form-checkbox
                    :checked="!details.company_already_created"
                    switch
                    inline
                  /></b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.payment')"
                  label-for="payment"
                >
                  <b-form-checkbox
                    v-model="details.payment"
                    :checked="details.payment"
                    switch
                    inline
                  /></b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.createdAt')"
                  label-for="status_code"
                >
                  <b-badge variant="light-primary">
                    {{ formatDatetime(details.createdAt) }}
                  </b-badge>
                </b-form-group></b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('common.general.package')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="this.package.name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('common.general.price')"
                  label-for="price"
                >
                  <b-form-input
                    id="price"
                    v-model="this.package.price"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!details.verified && details.statusCode == 'ACTIVE'">
              <b-col>
                <b-button
                  v-b-modal.modal-verify-prevent-closing
                  variant="primary"
                  class="mr-2"
                >
                  <span class="text-nowrap">{{
                    $t("common.general.verify")
                  }}</span>
                </b-button>
                <b-button
                  variant="primary"
                  @click="postUpdate()"
                >
                  <span class="text-nowrap">{{
                    $t("common.general.update")
                  }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </section>
    </b-overlay>
    <ListNodePage :id="id" />
    <ListAppointmentPage :prospect-i-d="id" />
    <b-modal
      id="modal-verify-prevent-closing"
      ref="my-modal-verify"
      :title="$t('common.form.title_confirm_verify')"
      :ok-title="$t('common.form.verify')"
      :cancel-title="$t('common.form.cancel')"
      cancel-variant="outline-secondary"
      @ok="handleOk"
    >
      <b-card-text>
        {{ $t("common.form.des_confirm_verify") }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  VBToggle,
  BModal,
  VBModal,
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BBadge,
  BButton,
  BCardText,
  BImg,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import packageServices from '@/services/packageServices';
import prospectServices from '@/services/prospectServices';
import { imageDefault } from '@/services/constant';
import ListNodePage from '@/views/pages/prospect/ListNotePage.vue';
import ListAppointmentPage from '@/views/pages/prospect/ListAppointmentPage.vue';
import commonServices from '@/services/commonServices';
export default {
  components: {
    ListNodePage,
    ListAppointmentPage,
    BOverlay,
    BModal,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BBadge,
    BButton,
    BCardText,
    BImg,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },

  data() {
    return {
      imageDefault,
      isLoading: false,
      id: this.$route.query.id,
      details: {},
      package: {},
      isLoadingTable: false,
      dataList: [],
      tableColumns: [
        { key: 'amount', label: this.$t('common.general.amount') },
        { key: 'name', label: this.$t('common.general.name') },
        {
          key: 'payment_method',
          label: this.$t('common.general.payment_method'),
        },
        { key: 'receipt_url', label: this.$t('common.general.receipt_url') },
        { key: 'createdAt', label: this.$t('common.general.createdAt') },
      ],
    };
  },
  created() {
    if (this.id) {
      this.getDetails();
    }
  },
  methods: {
    async getDetails() {
      this.isLoading = true;
      await prospectServices
        .getDetail(this.id)
        .then((result) => {
          if (result) {
            this.details = result.data.getProspect;
            if (this.details.packageID) {
              this.getPackageDetails(this.details.packageID);
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.verifyProspect();
    },
    async verifyProspect() {
      this.isLoading = true;
      await prospectServices
        .verify(this.details.id, true)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            }
            if (result.data.verifyProspect) {
              commonServices.showSuccess(this, 'msg.success.success');
              this.$router.push({
                name: 'customer-details',
                query: { id: result.data.verifyProspect.id },
              });
            }
            this.$nextTick(() => {
              this.$refs['my-modal-verify'].toggle('#toggle-btn');
            });
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getPackageDetails(id) {
      await packageServices
        .getDetail(id)
        .then((result) => {
          if (result) {
            this.package = result.data.getPackage;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    async postUpdate() {
      this.isLoading = true;
      await prospectServices
        .update(this.details)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            } else {
              commonServices.showSuccess(this, 'msg.success.success');
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    formatDatetime(val) {
      return commonServices.formatDatetime(val);
    },
  },
};
</script>
